<template>
  <template v-if="isSmall">
    <Container size="small" class="print:hidden">
      <Breadcrumbs :items="pageBreadcrumbs" />
    </Container>
    <div class="print:px-14">
      <component
        :is="componentName(element, components)"
        v-for="element in page.elements"
        :key="element.id"
        :element="element"
      />
    </div>
  </template>
  <ContentWithSidebar v-else-if="projectItems || useTableOfContents">
    <Container>
      <Breadcrumbs :items="pageBreadcrumbs" />
    </Container>

    <component
      :is="componentName(element, components)"
      v-for="element in contentElements"
      :key="element.id"
      :element="element"
      data-learning-recipe-with-sidebar
    />

    <template #aside>
      <component
        :is="components.project_items"
        v-if="projectItems"
        :element="projectItems"
        in-sidebar
      />
      <TableOfContents v-if="useTableOfContents" in-sidebar />
    </template>
  </ContentWithSidebar>
  <template v-else>
    <Container>
      <Breadcrumbs :items="pageBreadcrumbs" />
    </Container>

    <component
      :is="componentName(element, components)"
      v-for="element in contentElements"
      :key="element.id"
      :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
      :element="element"
      data-learning-recipe-with-sidebar
    />
  </template>
</template>

<script setup lang="ts">
  import { defineAsyncComponent } from "vue"
  import type { UseHeadInput } from "@unhead/vue"
  import { useMediaQuery } from "@vueuse/core"
  import { useNotificationStore } from "~/pinia/notifications"

  const NARROW_ELEMENTS = [
    "guide_header",
    "text_block",
    "how_to_step",
    "fragrance_teaser",
    "headline",
    "glossary",
    "documents_list",
    "testimonial",
    "pinterest_gallery",
    "pro_tip",
    "testimonial",
    "testimonials",
    "grid_columns",
    "table_of_contents",
  ]

  const isSmall = useMediaQuery("(max-width: 959px)")
  const components = {
    candle_recipe: defineAsyncComponent(
      () => import("~/alchemy/elements/candle_recipe.vue"),
    ),
    cards: defineAsyncComponent(() => import("~/alchemy/elements/cards.vue")),
    disqus_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/disqus_embed.vue"),
    ),
    divider: defineAsyncComponent(
      () => import("~/components/Divider/index.vue"),
    ),
    gallery: defineAsyncComponent(
      () => import("~/alchemy/elements/gallery.vue"),
    ),
    glossary: defineAsyncComponent(
      () => import("~/alchemy/elements/glossary.vue"),
    ),
    guide_header: defineAsyncComponent(
      () => import("~/alchemy/elements/guide_header.vue"),
    ),
    how_to_step: defineAsyncComponent(
      () => import("~/alchemy/elements/how_to_step.vue"),
    ),
    headline: defineAsyncComponent(
      () => import("~/alchemy/elements/headline.vue"),
    ),
    image_full_width: defineAsyncComponent(
      () => import("~/alchemy/elements/image_full_width.vue"),
    ),
    pinterest_gallery: defineAsyncComponent(
      () => import("~/alchemy/elements/pinterest_gallery.vue"),
    ),
    pro_tip: defineAsyncComponent(
      () => import("~/alchemy/elements/pro_tip.vue"),
    ),
    project_items: defineAsyncComponent(
      () => import("~/alchemy/elements/project_items.vue"),
    ),
    story_banner: defineAsyncComponent(
      () => import("~/alchemy/elements/story_banner.vue"),
    ),
    table_of_contents: defineAsyncComponent(
      () => import("~/components/TableOfContents/index.vue"),
    ),
    testimonial: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonial.vue"),
    ),
    testimonials: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonials/index.vue"),
    ),
    text_block: defineAsyncComponent(
      () => import("~/alchemy/elements/text_block.vue"),
    ),
    text_image_left: defineAsyncComponent(
      () => import("~/alchemy/elements/text_image_left.vue"),
    ),
    text_image_right: defineAsyncComponent(
      () => import("~/alchemy/elements/text_image_right.vue"),
    ),
    grid_columns: defineAsyncComponent(
      () => import("~/alchemy/elements/grid_columns.vue"),
    ),
    wistia_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/wistia_embed.vue"),
    ),
  }

  const props = defineProps<{
    page: AlchemyPage
  }>()

  const pageBreadcrumbs = usePageBreadcrumbs(props.page)

  const LEVEL_TRANSLATIONS = {
    1: "Beginner",
    2: "Intermediate",
    3: "Expert",
  }
  const notificationStore = useNotificationStore()
  const { componentName, getValue, getIngredient } = useAlchemy()

  const config = useRuntimeConfig()
  const BASE_URL = config.public.BASE_URL

  useHead(usePageInfo(props.page))

  let head: UseHeadInput = {}

  const guideHeader = props.page.elements.find(
    (el) => el.name === "guide_header",
  )

  const stepElements = props.page.elements.filter(
    (el) =>
      el.name === "how_to_step" &&
      el.nestedElements.some((el) => el.name === "text_block"),
  )

  const projectItems: AlchemyElement | undefined = props.page.elements.find(
    (el) => el.name === "project_items",
  )

  if (guideHeader && stepElements.length) {
    // Only output Schema if we have valid data
    const title = guideHeader.ingredients.find((ing) => ing.role === "title")
    const result = guideHeader.ingredients.find((ing) => ing.role === "result")
    const level = getValue<1 | 2 | 3>(guideHeader, "level") || 1
    const duration = guideHeader.ingredients.find(
      (ing) => ing.role === "duration",
    )
    const itemElements = projectItems?.nestedElements || []

    head = {
      script: [
        {
          type: "application/ld+json",
          innerHTML: {
            "@context": "https://schema.org/",
            "@type": "HowTo",
            name: title?.value,
            url: `${BASE_URL}${props.page.urlPath}`,
            yield: result?.value,
            educationalLevel: LEVEL_TRANSLATIONS[level],
            totalTime: `PT${duration?.value}M`,
            supply: itemElements
              .filter((item) => getValue(item, "schema_type") === "Supply")
              .map((el) => {
                const variant = getIngredient<AlchemyIngredientVariant>(
                  el,
                  "variant",
                )
                return {
                  "@type": "HowToSupply",
                  name: variant?.variantName,
                  image: variant?.imageUrl,
                  url: `${BASE_URL}${variant?.productUrl}`,
                }
              }),
            tool: itemElements
              .filter((item) => getValue(item, "schema_type") === "Tool")
              .map((el) => {
                const variant = getIngredient<AlchemyIngredientVariant>(
                  el,
                  "variant",
                )
                return {
                  "@type": "HowToTool",
                  name: variant?.variantName,
                  image: variant?.imageUrl,
                  url: `${BASE_URL}${variant?.productUrl}`,
                }
              }),
            step: stepElements.map((step) => {
              const title = getIngredient<AlchemyIngredientHeadline>(
                step,
                "title",
              )
              return {
                "@type": "HowToStep",
                name: title?.value,
                // We do not want query params in the URL
                url: title && `${BASE_URL}${props.page.urlPath}#${title.domId}`,
                image: getValue(step, "image"),
                itemListElement: step.nestedElements
                  .filter(({ name }) => name === "text_block")
                  .map((textBlock) => {
                    return {
                      "@type": "HowToDirection",
                      text: getIngredient<AlchemyIngredientRichtext>(
                        textBlock,
                        "text",
                      )?.strippedBody,
                    }
                  }),
              }
            }),
          },
        },
      ],
    }
  }

  useHead(head)

  const contentElements = computed(() => {
    return props.page.elements.filter((el) => el.name !== "project_items")
  })

  const useTableOfContents = computed(() => {
    return props.page.elements.some((el) => el.name === "table_of_contents")
  })

  onMounted(() => {
    notificationStore.announce(props.page.title)
  })
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
